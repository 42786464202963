<template>
  <!--
    Takes a test picture of a Photopoint Installation
  -->
  <div>
    <font-awesome-icon
      :class="['alt-pointer color-primary', { 'fa-spin' : loading}]"
      icon="sync-alt"
      style="vertical-align: middle; float: right;"
      @click="firstCall()"
    />
    <LoadingPlaceholder v-if="loading" />    
    <template v-else>
      <div class="crosshair mt-4">
        <init-svg
          v-if="triggerArea && triggerArea.length > 0"
          ref="initSvg"
          :trigger-area="triggerArea"
          :trigger-line="triggerLine"
          :focus-point="focusPoint"
          :img-height="imgHeight"
          :img-width="imgWidth"
        />
        <span
          v-show="showMinBlock"
          id="minBlock"
          class="top-right min"
        />
        <span
          v-show="showMinMinRatio"
          id="minMinRatio"
          class="top-right min-dottet"
        />
        <span
          v-show="showMinMaxRatio"
          id="minMaxRatio"
          class="top-right min-dottet"
        />
        <span
          v-show="showMaxBlock"
          id="maxBlock"
          class="top-right max"
        />
        <img
          v-if="webcamImage"
          id="webcamImageSelector"
          :src="`data:image/png;base64,${ webcamImage.base64Data }`"
          class="m-0 p-0 previewImage"
        >
      </div>
      <button
        class="btn btn-primary float-right mt-2"
        @click="saveConfigurationAndRestartApp()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="sync-alt"
        /><span>{{ $t('deviceDetailConfigurationEditComponent.saveAndRestart') }}</span>
      </button>
      <button 
        class="btn btn-primary float-right mt-2 mr-2" 
        @click="saveConfiguration()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="save"
        /><span>Save Configuration</span>
      </button>
    </template> 
  </div>
</template>
  
<script>
export default {
  name: 'PhotoPointTestPicture',
    components: {
      InitSvg: () => import('@/components/Actioncam/InitSvg.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    deviceId: {
      type: String,
      required: true
    },
    ptConfiguration: {
      type: Array
    }
  },
  data () {
    return {
      configuration: null,
      configurationDeviceUiid: null,
      loading: true,
      webcamImage: null,
      imgHeight: 0,
      imgWidth: 0,
      triggerLine: [],
      triggerArea: [], 
      focusPoint: [],
      showMinBlock: false,
      showMaxBlock: false,
      showMinMinRatio: false,
      showMinMaxRatio: false
    }
  },
  async created () {
    await this.firstCall();
  },
  methods: {
    async firstCall () {
      this.loading = true;
      await this.getWebcamImage();
      await this.getConfiguration();

      document.getElementById("webcamImageSelector").height = document.getElementById("webcamImageSelector").height * 2.5; //scale webcam image up
      this.imgHeight = document.getElementById("webcamImageSelector").height;
      this.imgWidth = document.getElementById("webcamImageSelector").width;

      if(this.configuration.triggerLine)
      {
        this.triggerLine = [];
        for (let i = 0; i < this.configuration.triggerLine.length; i++) {
          let newX = Math.round((this.configuration.triggerLine[i].x / 100) * this.imgWidth);
          let newY = Math.round((this.configuration.triggerLine[i].y / 100) * this.imgHeight);
          let coordinates = [];
          coordinates.push(newX);
          coordinates.push(newY);
          this.triggerLine.push(coordinates);
        }
      }
      if(this.configuration.trigger)
      {
        this.triggerArea = [];
        for (let i = 0; i < this.configuration.trigger.length; i++) {
          let newX = Math.round((this.configuration.trigger[i].x / 100) * this.imgWidth);
          let newY = Math.round((this.configuration.trigger[i].y / 100) * this.imgHeight);
          let coordinates = [];
          coordinates.push(newX);
          coordinates.push(newY);
          this.triggerArea.push(coordinates);
        }
      }
      if(this.configuration.focusPoint)
      {
        this.focusPoint = [];
        for (let i = 0; i < this.configuration.focusPoint.length; i++) {
          let newX = Math.round((this.configuration.focusPoint[i].x / 100) * this.imgWidth);
          let newY = Math.round((this.configuration.focusPoint[i].y / 100) * this.imgHeight);
          let coordinates = [];
          coordinates.push(newX);
          coordinates.push(newY);
          this.focusPoint.push(coordinates);
        }
      }
   
      let minBlockVal = this.ptConfiguration.find(x => x.includes(".blocksize="));
      if (minBlockVal) {
        minBlockVal = minBlockVal.split('=');
      }
      let maxBlockVal = this.ptConfiguration.find(x => x.includes(".maxblocksize="));
      if (maxBlockVal) {
        maxBlockVal = maxBlockVal.split('=');
      }
      let minRatioVal = this.ptConfiguration.find(x => x.includes(".aratiomin="));
      if (minRatioVal) {
        minRatioVal = minRatioVal.split('=');
      }
      let maxRatioVal = this.ptConfiguration.find(x => x.includes(".aratiomax="));
      if (maxRatioVal) {
        maxRatioVal = maxRatioVal.split('=');
      }

      if(minBlockVal && minBlockVal[1]) {
        this.showMinBlock = true;

        if(minRatioVal && minRatioVal[1]) {
          this.showMinMinRatio = true;
          let area = this.imgHeight * this.imgWidth * minBlockVal[1] / 100
          let h = Math.sqrt(area / minRatioVal[1]);
          let w = area / h;
          let minRatio = document.getElementById("minMinRatio");
          minRatio.style.height = h + 'px';
          minRatio.style.width = w + 'px';
        }
        
        if(minRatioVal && maxRatioVal[1]) {
          this.showMinMaxRatio = true;
          let area = this.imgHeight * this.imgWidth * minBlockVal[1] / 100
          let h = Math.sqrt(area / maxRatioVal[1]);
          let w = area / h;
          let maxRatio = document.getElementById("minMaxRatio");
          maxRatio.style.height = h + 'px';
          maxRatio.style.width = w + 'px';
        }

        let a = Math.sqrt((this.imgHeight * this.imgWidth * minBlockVal[1] / 100));
        let minBlock = document.getElementById("minBlock");
        minBlock.style.height = a + 'px';
        minBlock.style.width = a + 'px';
      }
      if(maxBlockVal && maxBlockVal[1]) {
        this.showMaxBlock = true;
        let a = Math.sqrt((this.imgHeight * this.imgWidth * maxBlockVal[1] / 100));
        let maxBlock = document.getElementById("maxBlock");
        maxBlock.style.height = a + 'px';
        maxBlock.style.width = a + 'px';
      } else {
        this.showMaxBlock = true;
        let maxBlock = document.getElementById("maxBlock");
        maxBlock.style.height = this.imgHeight + 'px';
        maxBlock.style.width = this.imgWidth + 'px';
        maxBlock.className = 'top-right max-small-border'
      }
      this.loading = false;
    },
    async getWebcamImage () {
      await this.axios.get(`/Actioncam/GetActioncamWebcamPhoto/${ this.deviceId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.webcamImage = response.data;
        });
    },
    async getConfiguration () {
      await this.axios.get(`/Actioncam/GetActioncamConfiguration/${ this.installationId }/${ this.deviceId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.configuration = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async saveConfigurationAndRestartApp (){
      if(!this.configurationDeviceUiid)
      {
        await this.getRestartDeviceUiid();
      }
      await this.saveConfiguration();
      await this.restartApp();
    },
    async getRestartDeviceUiid () {
      await this.axios.get(`/CentralDeviceManagement/GetFromInstallationId?installationId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          let configurationDevice = response.data.filter(p => p.applicationType == 'pt_controller' || p.applicationType == 'sc_finish');
          if(configurationDevice && configurationDevice.length == 1)
          {
            this.configurationDeviceUiid = configurationDevice[0].uuid;
          }
        });
    },
    async saveConfiguration (){
       let config = {
        trigger: this.$refs.initSvg.transformedTrigger,
        triggerLine: this.$refs.initSvg.transformedTriggerLine,
        focusPoint: this.$refs.initSvg.transformedFocusPoint,
        croprect: this.configuration.croprect
      };
      await this.axios.put(`/Actioncam/UpdateActioncamConfiguration?installationId=${ this.installationId }&deviceUuid=${ this.deviceId }`, config)
        .then((response) => {
          if (!response) {
            this.loading = false;
            return;
          }
          if (!response.status) {
            this.loading = false;
            return;
          }
          if (response.status != 200) {
            this.loading = false;
            return;
          }
          if (response.status == 200) {
            this.$snotify.success(this.$t('actionCamComponent.configurationSavedSuccessfully'));
          }
        });
    },
    async restartApp () {
      let commandRequest = {
        'Command': 'restartApp',
        'Args': ''
      };
      await this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ this.configurationDeviceUiid }`, commandRequest)
        .then((response) => {
          if (response.data && response.data.output) {
            this.$snotify.success(this.$t('restartAppSuccessful'));
          }
        });
    }
  }
}
</script>

<style>
body {
  box-sizing: content-box;
}
.container {
  /*   background-color: lightcoral; */
  margin: auto;
  position: relative;
  border: 1px solid red;
  background-image: url("http://sirotinja.com/img/tracking.jpg");
}
.draggable {
  width: 8px;
  height: 8px;
  background-color: darkblue;
  display: inline-block;
  padding: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
#drawtest {
  position: relative;
  margin: 0;
  border: 1px solid #d3d3d3;
}

.crosshair {
  position: relative;
}
.initSvgConfiguration {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  overflow: hidden;
}
#webcamImageSelector {
  width: 100%;
  height: auto;
  max-width: 750px;
}
.top-right {
  top: 0;
  right: 0;
  position: absolute;
  display: block;  
  pointer-events: none;
}

.top-right.min {
  border: 2px solid lightgreen;
}

.top-right.min-dottet {
  border: 2px solid lightgreen;
  border-style: dotted;
}

.top-right.max {
  border: 2px solid red;
}
.top-right.max-small-border {
  border: 1px solid red;
}
</style>


